<template>
    <v-row justify="center" class="py-5" align-content="center" style="height: 100%;">
        <v-col cols="12" sm="6">
            <template v-if="showRedirectCard">
                <v-card>
                    <v-toolbar dense flat :color="primaryColor" dark>
                        <v-toolbar-title>Sign in</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <p class="mb-0">
                            <v-progress-circular :color="primaryColor" indeterminate width="2" size="16" class="mr-2"></v-progress-circular>
                            <span v-if="redirectURL">Redirecting to <a :href="redirectURL">the sign in form</a>...</span>
                        </p>
                    </v-card-text>
                </v-card>
                <p class="mt-6 grey--text text--darken-2 text-center">Don't have an account yet? <router-link :to="{ name: 'signup', query: { email: this.$route.query.email } }">Sign up</router-link></p>
            </template>
            <v-alert type="error" v-if="isError">
                An error occurred while processing your request. Please try again or contact customer support.
            </v-alert>
        </v-col>
    </v-row>
</template>

<style>
</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        showRedirectCard: false,
        redirectURL: null,
        loginError: false,
        serverError: false,
        clientError: false,
        unknownError: false,
    }),
    computed: {
        ...mapState({
            focus: (state) => state.focus,
            session: (state) => state.session,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isError() {
            return this.serverError || this.clientError || this.unknownError || this.loginError;
        },
    },
    watch: {
        focus() {
            this.redirect();
        },
    },
    methods: {
        async signin() {
            if (this.$route.query.intent === 'switch-user') {
                await this.$client.main().authn.logout();
                await this.$store.dispatch('refresh', { force: true }); // TODO: instead of another request to server, just update isAuthenticated and userId in vuex cache directly to false/null
            }

            // if user arrived here with a login token from loginfront, we need to process that; otherwise, redirect to loginfront to start the sign in
            const token = this.$route.query.loginfront; // TODO: for loginshield it is this.$route.query.loginshield_token
            if (token) {
                this.checkLoginToken(token);
            } else if (this.isAuthenticated) {
                // if user is already authenticated don't make them sign in again, just redirect to the dashboard
                this.redirectAfterLogin();
            } else {
                this.startLogin();
            }
        },
        async startLogin() {
            try {
                const startLoginResponse = await this.$client.main().authn.startLogin();
                const { redirect } = startLoginResponse;
                if (redirect) {
                    this.redirectURL = redirect;
                    this.redirect();
                } else {
                    console.log(`startLogin did not get redirect; server response: ${JSON.stringify(startLoginResponse)}`);
                    this.loginError = true;
                }
            } catch (err) {
                if (err?.response?.status >= 500) {
                    this.serverError = true;
                } else if (err?.response.status >= 400) {
                    this.clientError = true;
                } else {
                    this.unknownError = true;
                }
            }
        },
        redirect() {
            // show a link after 2 seconds in case auto-redirect fails or takes a long time
            setTimeout(() => {
                this.showRedirectCard = true;
            }, 2000);
            // use replace so that when user taps 'back' button from there, they won't
            // end up being redirected again to where they just wanted to come back from
            if (typeof window.location.replace === 'function') {
                window.location.replace(this.redirectURL);
            } else {
                window.location.href = this.redirectURL;
            }
        },
        async checkLoginToken(token) {
            try {
                this.$store.commit('loading', { checkLoginToken: true });
                // TODO: when loginfront is merged to loginshield, this query parameter will be renamed to loginshield_token; so we can remove the references to 'loginfront'
                if (token) {
                    const { isAuthenticated, intent, intent_params: intentParams } = await this.$client.main().authn.checkLogin({ token });
                    if (!isAuthenticated) {
                        this.$store.commit('setSession', { isAuthenticated: false });
                        this.$store.commit('setUser', null);
                        // this.$bus.$emit('snackbar', { type: 'error', headline: 'You must sign in to continue' });
                        this.startLogin();
                        return;
                    }
                    await this.$store.dispatch('refresh');
                    this.redirectAfterLogin(intent, intentParams);
                    return;
                }
                console.log('checkLoginToken called without a token');
                this.loginError = true;
            } catch (err) {
                console.error('start login failed', err);
                this.loginError = true;
            } finally {
                this.$store.commit('loading', { checkLoginToken: false });
            }
        },
        async redirectAfterLogin(intent, intentParams) {
            if (intent === 'account_dashboard' && intentParams?.account_id) {
                // the user may be arriving from unicornsprings for a new account that isn't in our system yet,
                // so before we forward them to account dashboard, check their access to the specified account
                // so they don't end up there with a 403 forbidden error trying to load account data.
                try {
                    const result = await this.$client.user(this.session.userId).self.check({ item: 'account', account_id: intentParams.account_id });
                    console.log(`LoginActivity.vue redirectAfterLogin check account result: ${JSON.stringify(result)}`);
                    this.$router.push({ name: 'account-dashboard', params: { accountId: intentParams.account_id } });
                } catch (err) {
                    console.error(`redirectAfterLogin: account check failed for account id: ${intentParams.account_id}`, err);
                    this.$router.replace({ name: 'user-dashboard', query: { intent: 'after-login' } });
                }
            } else {
                this.$router.replace({ name: 'user-dashboard', query: { intent: 'after-login' } });
            }
        },
    },
    mounted() {
        this.signin();
    },
};
</script>
